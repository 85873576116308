@import "variables";
// includes
@import "framework/functions";
@import "framework/framework";
@import "framework/base";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
  font-size: 16px;
  line-height: 1.2;
  overflow-x: hidden;
}

body {
  background-color: $turquois;
  color: $white;
  font-family: $primary-font;
  font-weight: normal;
  font-size: rems(16);
  line-height: 1.2;
  overflow: hidden;
  position: relative;
}

a {
  color: inherit;
  transition: .3s all;

  @include hover-focus {
    color: $primary-color;
  }
}

.container {
  max-width: rems(1600);
}

.copyright {
  font-size: rems(10);
  text-transform: uppercase;
  text-align: center;
}

h1 {
  a {
    font-weight: 400;
    text-transform: uppercase;
    font-size: rems(34);
  }
}

h2 {
  color: $white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  .header-bg-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .header-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #34909C 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 0.3;
  }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1150px) {
      flex-direction: column;
      text-align: center;

      .social {
        margin: rems(20) 0;
      }
    }

    ul {
      display: block;
      list-style: none;
      padding: 0 rems(10);
      margin: 0;

      li {
        display: inline-block;
      }
    }
  }

  .nav {
    a {
      font-size: rems(20);
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
    }

    li+li {
      margin-left: 3em;



      @media screen and (max-width: 1260px) {
        margin-left: .8em;
      }
    }
  }

  .social {
    color: $white;
    font-size: rems(16);

    li+li {
      margin-left: .7em;
    }

    a {
      @include hover-focus {
        color: $primary-color;
      }
    }
  }
}

#main {
  h2 {
    font-size: rems(36);
    letter-spacing: .05em;
    text-transform: uppercase;
    margin: 1em auto;
    text-align: center;
    font-weight: normal;
  }
}

#hero {
  background-image: url('../img/reskin-1/hero-horizontal.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  text-align: center;
  padding: 10vh 30px;

  @media screen and (max-width: 740px) {
    background-image: url('../img/reskin-1/hero-vertical.png');
    min-height: 80vh;
  }

  img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 90vh;
    transform-origin: center;
    animation: 30s spin linear infinite;
  }
}

.multi-section-bg {
  background-image: url('../img/reskin-1/bg-multi-section.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#music {
  padding: rems(50) 0 rems(80);

  @media screen and (max-width: 640px) {
    padding: rems(50) 0 rems(50);
  }

  h2 {
    margin-top: 0;
  }

  .buy-links {
    list-style: none;
    padding: rems(20) 0;
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
    }

    a {
      font-size: rems(22);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2em;
      width: 2em;
      border: 3px solid $black;
      color: $black;
      border-radius: 50%;
      text-decoration: none;
      margin: .2em;

      svg {
        display: block;
      }

      path {
        fill: $black;
      }

      @include hover-focus {
        color: $white-o;
        background-color: $black;

        path {
          fill: $white-o;
        }
      }
    }

    svg {
      height: 1em;
      width: auto;
    }
  }

  .owl-carousel {
    width: 100%;
    margin: 0 auto;
    max-width: rems(770);
    padding: 0 rems(50);

    @media screen and (max-width: 640px) {
      padding: 0 rems(30);
    }

    .item {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }

      .btn {
        display: inline-block;
        padding: rems(10) rems(15);
        text-transform: uppercase;
        text-decoration: none;
        margin-top: rems(15);
        background-color: $black;
        color: $white;

        @include hover-focus {
          background-color: $primary-color;
        }
      }

    }


    .owl-prev,
    .owl-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: rems(30);
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }
  }
}

#tour {
  padding: rems(150) 0 rems(30);

  @media screen and (max-width: 960px) {
    padding-top: rems(30);
  }

  .container {
    max-width: rems(850);
  }

  .seated-follow-box {
    display: none;
  }

  .seated-event-row {
    font-size: rems(16);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .1em;

    .seated-event-link1,
    .seated-event-link2 {
      font-size: rems(16);
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: .1em;
      display: inline-block;
      padding: rems(10) rems(15);
      text-transform: uppercase;
      text-decoration: none;
      background-color: $white;
      border: none;
      border-radius: 0;
      ;
      color: $black;
      font-weight: 600;

      @include hover-focus {
        background-color: $primary-color;
        color: $white;
      }
    }
  }

  #events {
    .event {
      font-size: rems(16);
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: rems(40);
      letter-spacing: .1em;

      @media screen and (max-width: 800px) {
        flex-direction: column;
        line-height: 1.3;
      }

      .event-location {
        flex: 1;
      }

      .event-date {
        flex: .5;
      }


    }

    &:not(.seeall) {
      .event:nth-child(n+6) {
        display: none;
      }
    }
  }

  p {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }

  .btn {
    display: inline-block;
    padding: rems(10) rems(15);
    text-transform: uppercase;
    text-decoration: none;
    background-color: $white;
    color: $black;
    font-weight: 600;

    @include hover-focus {
      background-color: $primary-color;
      color: $white;
    }
  }

  .more {
    text-align: center;
  }
}

#videos {
  color: $white;
  padding: rems(70) 0 rems(120);

  @media screen and (max-width: 640px) {
    padding: rems(20) 0 rems(50);
  }

  .container {
    max-width: rems(1150);
  }

  .owl-carousel {
    text-align: center;
    width: 100%;
    padding: 0 rems(50);

    @media screen and (max-width: 640px) {
      padding: 0 rems(20);
    }

    .owl-prev,
    .owl-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: rems(30);
    }

    p {
      margin-top: rems(30);
      font-size: rems(16);
      text-transform: uppercase;
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }
  }
}

#newsletter {
  padding: rems(50) 0 rems(100);
  color: $primary-color;
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 992px) {
    padding: rems(50) 0 rems(200);
  }

  .float {
    position: absolute;
    left: rems(30);
    bottom: rems(0);
    pointer-events: none;
    width: 20vw;

    @media screen and (max-width: 992px) {
      width: rems(190);
    }
  }

  #mc-embedded-subscribe-form {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: rems(16);

    input {
      display: block;
      margin: rems(10) 0;
      border: none;
      border-radius: 0;
      background: none;
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      outline: none;

      &[type='email'] {
        width: 100%;
        max-width: rems(400);
        border-bottom: 1px solid $turquois;

        &::placeholder {
          color: $white;
        }
      }

      &[type='submit'] {
        background-color: $primary-color;
        color: $white;
        padding: rems(10) rems(20);
        transition: .3s all;
        cursor: pointer;

        @include hover-focus {
          background-color: $white;
          color: $primary-color;
        }
      }
    }
  }
}

#footer {
  background-color: $turquois;
  padding: rems(32) 0;

  .copyright {
    color: $white;
    text-transform: uppercase;
  }
}

#popup {
  position: relative;
  text-align: center;
  width: rems(500);
  max-width: 100%;
  padding: 0 rems(10);
  margin: rems(30) auto;

  .mfp-close {
    font-size: rems(40);
    top: rems(-10);
    right: rems(-30);
    color: $white;
    opacity: 1;
    transition: .3s all;

    @include hover-focus {
      color: $primary-color;
    }

    @media screen and (max-width: 600px) {
      top: rems(-40);
      right: 0;
    }
  }

  a {
    img {
      display: block;
    }

    img:nth-child(2) {
      display: inline-block;
      position: relative;
      top: rems(-40);
      z-index: 20;
      width: rems(100);
      transition: .3s all;

      @media screen and (max-width: 400px) {
        width: rems(80);
        top: rems(-30);
      }
    }

    @include hover-focus {
      img:nth-child(2) {
        filter: brightness(.2) invert(1);
      }
    }
  }
}