//settings
$fa-font-path: "../font";
$html-font-size: 16;

// Colors //
$black: #323720;
$white-o: #efeae2;
$white: #ffffff;
$primary-color: #DB7E00;
$green: #939588;
$gray: #424650;
$turquois: #34909C;

//Fonts
$primary-font: 'BaskervilleURW', sans-serif;
